import * as React from 'react';
import { cloneElement} from 'react';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    DeleteButton,
    ExportButton,
    SaveButton,
    ShowButton,
    EditButton,
    sanitizeListRestProps,
    Toolbar,
    Link
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {Typography, Select,Button, MenuItem} from "@material-ui/core"

const queryString = require('query-string');
export const EditToolbar = props => {
const redir = props
console.log(redir)
return (
    <Toolbar {...props} >
        <SaveButton
            label="Sauvegarder"
            redirect="show"
            submitOnEnter={true}
        />
        <SaveButton
            label="post.action.save_and_add"
            redirect={false}
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);
}

export const ShowActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        removeCreateButton,
        basePath,
        resource,
        data,
        hasShow,
        ...rest
    } = props;
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                context: 'button',
            })}
            <EditButton basePath={basePath} label="éditer" record={data}  />
            <DeleteButton basePath={basePath} label="Supprimer"/>
            {removeCreateButton?"":<CreateButton basePath={basePath} label="Créer" />}
                    <DeleteButton label="Supprimer" basePath={basePath} record={data} resource={resource} />

        </TopToolbar>
    );
};


export const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        removeCreateButton,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <>
        {props.resource==="categories" && <AllButtons {...props}></AllButtons>}
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {removeCreateButton?"":<CreateButton basePath={basePath} label="Créer" />}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={1000000}
                label="Exporter"
            />
        </TopToolbar>
        </>
    );
};
export const EditActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        removeCreateButton,
        basePath,
        resource,
        data,
        hasShow,
        ...rest
    } = props;
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                context: 'button',
            })}
            {/* <SaveButton/> */}
            {hasShow?<ShowButton basePath={basePath} label="Voir" record={data}  />:""}
            <DeleteButton basePath={basePath} label="Supprimer"/>
            {removeCreateButton?"":<CreateButton basePath={basePath} label="Créer" />}
                    <DeleteButton label="Supprimer" basePath={basePath} record={data} resource={resource} />

        </TopToolbar>
    );
};


export const ListPagination = (props) => {
    
    const { page, perPage, total, setPerPage, setPage } = props;
    const nbPages = Math.ceil(total / perPage) || 1;
    const upperLimitElements =(page*perPage)
    const handleChange = event => setPerPage(event.target.value);
    return (

        nbPages >= 1 &&
            <Toolbar style={{justifyContent:"flex-end"}}>
                <div style={{display:"flex",justifyContent:"flex-end"}}>
                {page > 1 &&
                    <Button  color="primary" key="prev" onClick={() => setPage(page - 1)}>
                        <ChevronLeft />
                        Précédent
                    </Button>
                }
                <div style={{display:"flex", justifyContent:"space-between"}}>
            <Typography style={{alignSelf:"center", position:"relative", bottom:"0px", fontSize:"80%"}} >| {((page-1)*perPage)+1}-{upperLimitElements>total?total:upperLimitElements} sur {total} | </Typography>  {/* Contains the <= 1-10 sur 50 1 ... 5 6 7 => */}
            <Typography style={{alignSelf:"center", position:"relative", bottom:"0px", fontSize:"80%", marginLeft:"45px", marginRight:"10px"}} >Nombre d'éléments par page :</Typography>  {/* Contains the <= 1-10 sur 50 1 ... 5 6 7 => */}
            
            <Select
            style={{marginBottom:"0px",  fontSize:"80%"}}
            value={perPage}
            onChange={handleChange}
            >
                <MenuItem value={10} >10</MenuItem>
                <MenuItem value={25} >25</MenuItem>
                <MenuItem value={50} >50</MenuItem>
                <MenuItem value={100} >100</MenuItem>
            </Select>
            {page>3?<Button color="primary" onClick={() => setPage(1)}>1</Button> :""}
            
            {page>3?<Typography style={{alignSelf:"center"}} >...</Typography>:""}
            {page>2?<Button color="primary" onClick={() => setPage(page-2)} >{page-2}</Button>:""}{page>1?<Button color="primary" onClick={() => setPage(page-1)}>{page-1}</Button>:""}
            
            <Button color="primary" disabled>{page}</Button>{nbPages>page+1?<Button color="primary" onClick={() => setPage(page+1)}>{page+1} </Button>:""}

            {nbPages>page+2?<Button color="primary" onClick={() => setPage(page+2)}>{page+2}</Button>:""} 
            {nbPages>page+3?<Typography style={{alignSelf:"center"}} >...</Typography>:""} 
            
            {nbPages>page?<Button color="primary" onClick={() => setPage(nbPages)}>{nbPages}</Button>:""}
                </div>
                {page !== nbPages &&
                    <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
                        Suivant
                        <ChevronRight />
                    </Button>
                }
                </div>
            </Toolbar>
    );
}


const AllButtons = (props) => {
    const drugs = {state:{record: { 
        title_fr: "Drogues",
        title_en: "Drugs",
        title_sp: "Drogas",
    },
    name:"drugs"
},
    style: {
        backgroundColor:"black",
        color:"white",
        marginTop:"16px", marginLeft:"5px",
    }
}
    const currency = {state:{record: { 
        title_fr: "Monnaie",
        title_en: "Currency",
        title_sp: "Moneda",
    },
    name:"currency"
},
    style: {
        backgroundColor:"green",
        color:"white",
        marginTop:"16px", marginLeft:"5px",
    }

}
    const travel = {state:{record: { 
        title_fr: "Quand voyager",
        title_en: "When to travel",
        title_sp: "Cuando viajar",
    },
    name:"travel"
},
    style: {
        backgroundColor:"#0066FF",
        color:"white",
        marginTop:"16px", marginLeft:"5px",
    }

}
    const taxi = {state:{record: { 
        title_fr: "Taxi",
        title_en: "Taxi",
        title_sp: "Taxi",
    },
    name:"taxi"
},
    style: {
        backgroundColor:"#FFCC00",
        color:"black",
        marginTop:"16px", marginLeft:"5px",
    }

}
    const clothing = {state:{record: { 
        title_fr: "Vestimentaire",
        title_en: "Clothing",
        title_sp: "Ropa",
    },
    name:"clothing"
},
    style: {
        backgroundColor:"grey",
        color:"white",
        marginTop:"16px", marginLeft:"5px",
    }

}
    const vaccination = {state:{record: { 
        title_fr: "Vaccination",
        title_en: "Vaccination",
        title_sp: "Vacunacion",
    },
    name:"vaccination"
},
    style: {
        backgroundColor:"white",
        color:"black",
        marginTop:"16px", marginLeft:"5px",
    }

}
    const visa = {state:{record: { 
        title_fr: "Visa",
        title_en: "Visa",
        title_sp: "Visa",
    },
    name:"visa"
},
    style: {
        backgroundColor:"#634133",
        color:"white",
        marginTop:"16px", marginLeft:"5px",
    }

}
    const insurance = {state:{record: { 
        title_fr: "Assurance",
        title_en: "Insurance",
        title_sp: "Seguro",
    },
    name:"insurance"
},
    style: {
        backgroundColor:"#E0FFFF",
        color:"black",
        marginTop:"16px", marginLeft:"5px",
    }

}
    const base = [drugs, currency, travel, taxi, clothing, vaccination, visa, insurance]
    return <div style={
        {
            display:"flex",
            width:"70%",
            position:"relative",
            left:"-6vw"
        }
    }>{base.map(model => <CreateCategory goal={model} />)}</div>
}
const CreateCategory = ({ record, goal }) => {

   return( 
   <Button
        component={Link}
        to={{
            pathname: '/countries/create',
            state: goal.state,
        }}
        variant="contained"
        //color="primary"
        style={goal.style
        }
        size={"small"}
    >
        {goal.state.record.title_fr}
    </Button>
)};