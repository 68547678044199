import React from "react"
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {Typography} from "@material-ui/core"
import Logo from "../media/Logo.png"
const useStyles = makeStyles({
    title: {
        flex: 1,
        marginLeft:"15px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});
const CustomAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} elevation={1}>
            <img height="auto" width="2%" src={Logo} />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
