import React from "react";
import { Chip } from '@material-ui/core';

const MyChipsField = ({ record = {}, source }) => {
    const {content} = record;
    if(!content) {
        return null};              //prevents re-rendering issues when re-mounting the element through "previous page" on the browser, initializing the visual component to null before updating it on mount
    
    const containerClass = { display: "flex", flexDirection: "row", maxWidth: "35vw", flexWrap: "wrap" }
    return (
    <div style={containerClass}>
    {content.map((element) => {
                    //e.isPropagationStopped=true => Stops the propagation of the "edit" action. Allows us to specify element-specific behaviour (here, redirecting to the individual category of pdf to edit)
        return (
            <div key={element.id} onClick= { e => e.isPropagationStopped=true} >
                <Chip label={element.title_fr} >  </Chip>
        </ div>
            )
    })}
  </div>
    )
}
export default MyChipsField;