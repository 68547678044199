import React, { useState } from 'react';
import {
    Edit,
    ReferenceField,
    maxLength,
    minLength,
    SelectInput,
    required,
    SimpleForm,
    FileInput,
    TextInput,
    BooleanInput,
    TopToolbar,
    ExportButton,
    DateInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    FileField,
    BooleanField
} from 'react-admin';
import MyPdfField from "./pdfViewer"
import RichTextInput from 'ra-input-rich-text';
import {Button, Typography, Select, MenuItem} from "@material-ui/core"
import PositionViewer from "./positionViewer"
import {EditActions, EditToolbar} from "./Actions"

const PositionAside= (props) => {
    if (props.record){
    const {lat,lng} = props.record
    return(

    <>
    <iframe
  width="60%"
  height="600"
  frameborder="0" style={{border:"1px solid black", borderRadius:"7px", margin:"5%", marginRight:"6%"}}
  src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_KEY}&q=${lat},${lng}&zoom=12&language=fr`
  } allowfullscreen>
</iframe>
     </>
    )
}
else{
    return <></>
}
}


export const UserEdit = (props) => (
    <Edit title="Editer un utilisateur" {...props} actions={<EditActions removeCreateButton/>} >
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="nickname" label="Pseudonyme" />
            <TextInput source="lat" label="Latitude" />
            <TextInput source="lng" label="Longitude" />
            <TextInput source="phoneNumber" label="Téléphone" />
            <PositionViewer source="pos" label="Position" />
            <BooleanInput source="vigil" label="Vigilant"/>
            <BooleanInput source="active" label="Activé"/>
            <DateField source="birthDate" label="Date de naissance" /> 
        </SimpleForm>
    </Edit>
);

export const GroupEdit = (props) => (
    <Edit title="Editer un groupe" {...props} actions={<EditActions removeCreateButton/>} >
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="name" label="Nom" />
            <DateField source="createdAt" label="Date de création du groupe" showTime />
            <DateField source="endingDate" label="Date de fin du groupe" />
            <TextField source="price" label="Prix"  />
            <BooleanField source="isTemporary"  label="Temporaire" />
            <TextField source="membersCount" label="Membres" />
        </SimpleForm>
    </Edit>
);

export const AlertEdit = (props) => 

{
    const choices = [
        { id: 'ok', name: 'OK' },
        { id: 'verify', name: 'A vérifier' },
        { id: 'deactivated', name: 'Désactivée' },
    ]
    return(
    <Edit title="Editer une alerte" {...props} actions={<EditActions/>}  aside={<PositionAside/>} >
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput multiline source="description" label="Description" />
            <TextInput multiline source="moreInfo" label="Plus d'informations" />  
            <DateField source="createdAt" label="lancement" showTime />         
            <BooleanInput source="isUserInjured" label="Blessure"  />
            <BooleanInput source="isUserVictim" label="Victime" />            
            <BooleanInput source="isArchived" label="Archivé" />
            <SelectInput source="reportState" label="Etat de report : " 
             choices={choices} />
            <ReferenceField label="Catégorie d'alerte" source="alertTypeId" link={null} reference="alertTypes" sortable={false}>
                <TextField source="category" />
            </ReferenceField>
            <ReferenceField label="Sous-catégorie d'alerte" source="alertTypeId" link={null} reference="alertTypes">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Lanceur d'alerte" source="userId" link="show" reference="users">
                <TextField source="nickname"  />
            </ReferenceField>
            <ReferenceField label="Infos Agresseur" source="aggressorId" link={"show"} reference="aggressors">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField label="infos Victime" source="victimId" link={"show"} reference="victims">
                <TextField source="id" />
            </ReferenceField>
        </SimpleForm>
    </Edit>
);}
export const RetreatEdit = (props) => {
    
    const choices = [
        { id: 'ok', name: 'OK' },
        { id: 'verify', name: 'A vérifier' },
        { id: 'deactivated', name: 'Désactivée' },
    ]
    return(
    <Edit title="Editer un point de repli" {...props} actions={<EditActions/>} aside={<PositionAside/>}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput multiline source="title" label="Titre" />
            <TextInput multiline source="comment" label="Commentaire" />
            <TextInput source="phoneNumber" label="Numéro de téléphone" />
            <TextInput source="lat" label="Latitude" />
            <TextInput source="lng" label="Longitude" />
            <BooleanInput source="temporary" label="Temporaire"/>
            <BooleanInput source="validated" label="Validé" />
            <SelectInput source="reportState" label="Etat de report : " 
             choices={choices} />
        </SimpleForm>
    </Edit>
)};
export const ProductEdit = (props) => (
    <Edit title="Editer un produit" {...props} actions={<EditActions/>} >
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="name" label="Nom" />
            <TextInput source="type" label="Type de produit" />
            <TextInput multiline source="description"  label="Description" />
            <TextInput source="price" label="Prix" />
        </SimpleForm>
    </Edit>
);


const style = {
    pdfField: { maxWidth: "50vw", display: "flex", flexDirection: "column", alignItems: "center", position: "relative", left: "35%", bottom: "60vh" },
    pdfButton: { marginTop: "1vh" },
}
const Aside = (props) => (
    <div style={{ width:"50%", margin: '1em', height:"10%" }}>
        <MyPdfField style={{ display: "flex", flexDirection: "column", alignItems: "center",}} record={props.record} />
    </div>
);

export const PdfEdit = (props) => {
    const [dropZone, setDropZone] = useState(false);
    const validateIso2 = [required(), minLength(2), maxLength(2)]
// 
    return(
        
        <Edit aside={<Aside />} title="Editer une catégorie" component="Box" {...props} > 
            <SimpleForm >
             <TextInput disabled label="Id" source="id" />
            <TextInput  label="Code ISO2 pays" source="country_code" validate={validateIso2}/>
            <TextInput multiline label="Titre Français" source="title_fr" />
            <TextInput multiline label="Titre Anglais" source="title_en" />
            <TextInput multiline label="Titre Espagnol" source="title_sp" />
            {/* <TextInput multiline label="Description Français" source="description_fr" /> */}
            <RichTextInput source="description_fr" label="Description Français" />
            <RichTextInput source="description_en" label="Description Anglais" />
            <RichTextInput source="description_sp" label="Description Espagnol" />
            {/* <div style={{ display:"block"}}>
                <Button variant="outlined" color="primary" style={style.pdfButton} onClick={() => setDropZone(!dropZone)}>Changer PDF</Button>
            </div>
                <div style={dropZone ? {width:"100%"} : { display: "none" }} >
                    <FileInput label="PDF" source="file" accept="application/pdf" placeholder={<p>Amenez un fichier jusqu'à cette zone ou cliquez dessus pour sélectionner un nouveau PDF</p>}>
                        <FileField title="name" ></FileField>
               </FileInput>
                </div> */}
        </SimpleForm>
    </Edit>
);
};
// const PostShowActions = ({ basePath, data, resource }) => (
//     <TopToolbar>
//         <EditButton basePath={basePath} label="Editer" record={data} />
//         <ExportButton basePath={basePath} label="Editer" record={data}/>
//         {/* Add your custom actions */}
//     </TopToolbar>
// );
