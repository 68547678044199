import React from "react";
import Helmet from "react-helmet";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import {Button, Card, CardContent, makeStyles} from "@material-ui/core"
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment"
import "moment/locale/fr";

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];
const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
];
const WEEKDAYS_SHORT = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];

export default class DatePicker extends React.Component {
  static defaultProps = {
    numberOfMonths: 1
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.classes = props.classes
    this.setFilters = props.setFilters
    this.filterValues = props.filterValues
    this.modalClose = props.modalClose
    this.state = this.getInitialState();

  }

  getInitialState() {

    return {
      from: undefined,
      to: undefined
    };
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    const filters = {...this.filterValues}
    delete filters.from
    delete filters.to
    this.setFilters(filters)

    this.setState(
      {
      from: undefined,
      to: undefined
    }
    );

  }
  render() {
    const { from, to } = this.state;
    const filtering = () => {
      this.setFilters({...this.filterValues, from, to})}
    const modifiers = { start: from, end: to };
    

    return (
      <Card className={this.classes.cardDatePicker}>
        <>
        <Button onClick={() => this.modalClose()} color="secondary" variant="Text" style={{display:"flex", position:"absolute", left:"88%", top:"3%", padding:"5px"}}><CloseIcon color="secondary" variant="outlined" /></Button>
        </>
        <CardContent style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
        <p>
          {!from && !to && "Sélectionnez le premier jour."}
          {from && !to && "Sélectionnez le dernier jour."}
          {from &&
            to &&
            `Sélection du ${moment(from).format("LL")} au
                ${moment(to).format("LL")}`}{" "}
        </p>
        { from && !to &&
        <>
        <p>Voir alertes : </p>
          <Button variant="contained" onClick={() => this.setFilters({...this.filterValues, to:from})} style={{marginTop:"0.5vh", marginBottom:"3vh"}} color="primary" >avant </Button>
          <Button variant="contained" onClick={() => this.setFilters({...this.filterValues, from})} color="primary" >après </Button>
        <p>le {moment(from).format("LL")}</p>  
        </>
        }
        {from && to && (
              <>
              <br/>
            <Button variant={"outlined"} color={"secondary"}  onClick={this.handleResetClick}>
              Réinitialiser
            </Button>
            <Button variant={"contained"} color={"primary"} style={{margin:"20px"}} onClick={filtering} >Voir les alertes</Button>
            </>
          )}
        
        </CardContent>
        <CardContent>
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          locale="fr"
          months={MONTHS}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
        />
        </CardContent>
        <Helmet>
          <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
        </Helmet>
      </Card>
    );
  }
}
