import {Filter, SearchInput} from "react-admin";
import React from "react";
import {MenuItem, Select} from "@material-ui/core";
import {useStandardStyle} from "./GroupsFilter.style";

export const GroupsFilter = props => {
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    } = props

    return (<Filter {...props}>
            <p alwaysOn >Filtres : </p>
            <SearchInput placeholder="Chercher par nom..." source="name" alwaysOn />

            <GroupsFilterList {...props} alwaysOn />
            <p alwaysOn> Total : {props.total}</p>

        </Filter>
    )
}

const GroupsFilterList = (props) => {
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    } = props;
    const classes = useStandardStyle();
    const handleChange = e => {
        setFilters(filterRules(e.target.value))
    }
    const filterRules = value => {
        if (value === 0) {
            const filters = { ...filterValues }
            delete filters.groupType
            return filters
        }
        else {
            return { ...filterValues, groupType: value }

        }

    }
    return (
        <Select
            className={classes.select}
            label={props.label}
            value={!!filterValues.groupType ? filterValues.groupType : 0}
            onChange={handleChange}
        >
            <MenuItem value={0} >Tous les groupes</MenuItem>
            <MenuItem value={"temporary"}>Groupes temporaires</MenuItem>
            <MenuItem value={"notTemporary"}>Groupes à durée indéterminée</MenuItem>

        </Select>
    )
}
