import React, { useState, useEffect, useMemo } from "react";
import { Filter, SearchInput, useQueryWithStore, Loading, Error, useRefresh, useListContext } from 'react-admin';
import { makeStyles, Chip, Select, MenuItem, Button } from '@material-ui/core';
import _ from "lodash";
import Picker from "./DatePicker"
import { DateUtils } from "react-day-picker";
const useStandardStyle = makeStyles(theme => ({
    select: {
        marginBottom: theme.spacing(1),
    },
    tag: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginBottom: theme.spacing(1),

    },
    p: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),

    },
    cardDatePicker : {
        borderTop:"3px solid #0066FF", 
        display:"flex", 
        position:"absolute", 
        top:"20vh", left:"10vw", 
        zIndex:30, opacity:1, 
        backgroundColor:"white", 
        padding:"20px",
        margin:"20px"}
}));

const RetreatsFilterList = (props) => {
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    } = props;

    const classes = useStandardStyle();
    const handleChange = e => {
        setFilters(filterRules(e.target.value))
    }
    const filterRules = value => {
        if (value === 0) {
            const filters = { ...filterValues }
            delete filters.type
            return filters
        }
        else {
            return { ...filterValues, type: value }
        }

    }
    return (
        <Select
            className={classes.select}
            label={props.label}
            value={!!filterValues.type ? filterValues.type : 0}
            onChange={handleChange}
        >
            <MenuItem value={0} >Tous les lieux de protection</MenuItem>
            <MenuItem value={"temporary"}>Lieux temporaires</MenuItem>
            <MenuItem value={"definitive"}>Lieux définitifs</MenuItem>
            <MenuItem value={"reported"}>Lieux reportés</MenuItem>
            <MenuItem value={"notValidated"}> Lieux non validés</MenuItem>
        </Select>
    )
}

const AlertTypesFilter = (props) => {
    const { setFilters, filterValues } = props
    const { loaded, error, data } = useQueryWithStore({
        type: 'simpleGet',
        resource: 'alertTypes',
    });
    const classes = useStandardStyle();

    //we want a list of names
    //we want a list of categories
    // we want to sort by names and categories, but 
    //

    const [selected, setSelected] = useState({})
    const [names, setNames] = useState([]), [categories, setCategories] = useState([])

    useEffect(() => {
        let namePool = []
        let categoryPool = []
     //  console.log('data',data)
        if (Array.isArray(data)) {
            categoryPool=_.uniq(data.map(alertType => alertType.category))
            namePool = _.uniq(data.map(alertType => alertType.name))
            setCategories(categoryPool)
        }
        if (selected.category) {
            const correspondingNames = data.filter((alertType) => alertType.category === selected.category)
            const newPool = namePool.filter((name) => _.some(correspondingNames, ['name', name]))

            setNames(
                newPool
            )
            if(selected.name) {
                // console.log(newPool)
                // console.log(selected.name)
                // console.log(newPool.some(name => name===selected.name))

                if(!newPool.some(name => name===selected.name)){
                    const obj = { ...selected }
                    delete obj.name
                    setSelected(obj)
                }
                //setFilters({...filterValues, })
            }
        } else {
            if (Array.isArray(data)) {
                setNames(_.uniq(data.map(alertType => alertType.name)))
            }

        }
        if(!selected.name && !selected.category){
            const filters = filterValues
            delete filters.alertTypeId
            setFilters(filters)
        }
        if(selected.name&&selected.category){
            const index = data.findIndex((el) => el.name ===selected.name && el.category===selected.category)
            if(data[index]){
                setFilters({...filterValues,alertTypeId:data[index].id||filterValues.ids})

            }
            else{
                const obj = filterValues
                delete obj.alertTypeId
                setFilters({...obj})
            }

        }
        else if(selected.name && !selected.category){
            const nameIds = data.filter(element => element.name===selected.name)
            setFilters({...filterValues,alertTypeId:nameIds.map(el => el.id||filterValues.ids)})

        }
        else if(!selected.name && selected.category){
            const categIds = data.filter(element => element.category===selected.category)
            setFilters({...filterValues,alertTypeId:categIds.map(el => el.id||filterValues.ids)})

        }
    }, [selected, data])

    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }

    const handleChangeCategories = e => {
        setFilters(filterRulesCategories(e.target.value))
    }
    const filterRulesCategories = value => {
        if (!value) {
            const obj = { ...selected }
            delete obj.category
            setSelected(obj)
            // const filters = {...filterValues}
            //     delete filters.alertTypeId
            //     return filters
        }
        setSelected({ ...selected, category: value })
    }
    const handleChangeNames = e => {
        filterRulesNames(e.target.value)
    }
    const filterRulesNames = value => {
        if (!value) {
            const obj = { ...selected }
            delete obj.name
            setSelected(obj)



            // const filters = {...filterValues}
            //     delete filters.alertTypeId
            //     return filters
        }
        setSelected({ ...selected, name: value })
    }


    return (
        <>
            <p className={classes.p}>Catégories : </p>

            <Select
                className={classes.select}
                label={props.label}
                value={selected.category || 0}
                onChange={handleChangeCategories}
            >
                <MenuItem value={0} >Toutes</MenuItem>
                {categories.map(category => {
                    return (
                        <MenuItem value={category} >{category}</MenuItem>
                    )
                })}

            </Select>
            <p className={classes.p}>Sous-Catégories :</p>
            <Select
                className={classes.select}
                label={props.label}
                value={selected.name || 0}
                onChange={handleChangeNames}
            >
                <MenuItem value={0} >Toutes</MenuItem>

                {names.map(category => {
                    return (
                        <MenuItem value={category} >{category}</MenuItem>
                    )
                })}

            </Select>
        </>
    )
};

const RetreatsFilter = props => {
    return (
        <Filter {...props}>
            <p alwaysOn >Filtres : </p>
            <SearchInput placeholder="Chercher par titre..." source="title" alwaysOn />
            <RetreatsFilterList {...props} alwaysOn />
            <p alwaysOn> Total : {props.total}</p>
        </Filter>
    );
}

const AlertsFilterList = (props) => {
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
   // } = props
    // useEffect(() => {
    //     const setDefaultFilters = () => {
    //         console.log(" here are filters : ", filterValues)
    //         if (props.hasAdditionalFilters) {
    //             //setFilters({ ...filterValues, ...props.filterDefaultValues })
    //         }
    //         else if (filterValues.id||filterValues.ids) {
    //             console.log("washed")
    //             setFilters({})

    //         }
    //     }

    //     setDefaultFilters()
    // }, [])

    } = useListContext()
    const classes = useStandardStyle();
    const handleChange = e => {
        setFilters(filterRules(e.target.value))
    }
    const filterRules = value => {
        if (value === 0) {
            const filters = { ...filterValues }
            delete filters.type
            return filters
        }
        else {
            return { ...filterValues, type: value }

        }

    }
    return (
        <Select
            className={classes.select}
            label={props.label}
            value={!!filterValues.type ? filterValues.type : 0}
            onChange={handleChange}
        >
            <MenuItem value={0} >Toutes</MenuItem>
            <MenuItem value={"active"}>En cours</MenuItem>
            <MenuItem value={"archived"}>Archivées</MenuItem>
            <MenuItem value={"anonymous"}>Anonymisées</MenuItem>
            <MenuItem value={"reported"}>Reportées</MenuItem>

        </Select>
    )
}








const UsersFilterList = (props) => {
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    } = props;
    const classes = useStandardStyle();
    const handleChange = e => {
        setFilters(filterRules(e.target.value))
    }
    const filterRules = value => {
        if (value === 0) {
            const filters = { ...filterValues }
            delete filters.userType
            return filters
        }
        else {
            return { ...filterValues, userType: value }

        }

    }
    return (
        <Select
            className={classes.select}
            label={props.label}
            value={!!filterValues.userType ? filterValues.userType : 0}
            onChange={handleChange}
        >
            <MenuItem value={0} >Tous les utilisateurs</MenuItem>
            <MenuItem value={"notVigil"}>Utilisateurs non vigilants</MenuItem>
            <MenuItem value={"vigil"}>Utilisateurs vigilants</MenuItem>
            <MenuItem value={"isMinor"}>Mineurs</MenuItem>

        </Select>
    )
}





//////////
const UsersFilter = props => {
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    } = props
    const classes = useStandardStyle();

    return (<Filter {...props}>
        <p alwaysOn >Filtres : </p>
        <SearchInput placeholder="Chercher par pseudo..." source="nickname" alwaysOn />
        <SearchInput placeholder="Chercher par email..." source="email" alwaysOn />
        <SearchInput placeholder="Chercher par numéro..." source="phoneNumber" alwaysOn />

        <UsersFilterList {...props} alwaysOn />
        <p alwaysOn> Total : {props.total}</p>

    </Filter>
    )
}

const DatePicker = (props) => {
    const classes = useStandardStyle();
   const {from, to} = props.filterValues
   useEffect(() => {
    if(from||to){
        const obj = {...props.filterValues}
        delete obj.from
        delete obj.to
    props.setFilters(obj)
        }  
    return () => {
   if(from||to){
        const obj = {...props.filterValues}
        delete obj.from
        delete obj.to
    props.setFilters(obj)
        }   
    }
   }, [])

   const [visible, setVisible] = useState(false)
   const modalClose = () => setVisible(false)
    return(
    <>
        <Button className={classes.button} variant={"outlined"} onClick={() => setVisible(!visible)} >{visible?"Cacher":from||to?"Voir date choisie":'Choisir une date'}</Button>
        <div style={{display:visible?"block":"none"}}>
        <Picker classes={classes} modalClose={modalClose} {...props}></Picker>
        </div>
    </>
    )
}
const AlertsFilter = props => {
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
        
    } = useListContext()
    const visibility = props.visibility||{always:true}
    const classes = useStandardStyle();
    const [visible, setVisible] = useState(visibility.visible||false)
    return (
        <Filter {...props}>
            {visibility.always?null:<Button  className={classes.button} color={"secondary"} alwaysOn onClick={() =>{
            setVisible(!visible)
            visibility.setVisible(!visible)}}>   
                 {visible?`Cacher les alertes de l'utilisateur`:"Voir les alertes de l'utilisateur"} </Button>}
             {visibility.always?null:<br/>}
        {visible||visibility.always?    <p alwaysOn >Filtres : </p> :null}
        {visible||visibility.always? props.hasAdditionalFilters ? props.additionalFilters : <div alwaysOn style={{ display: "none" }}></div>:null}
        {visible||visibility.always? <AlertsFilterList {...props} record="record" alwaysOn />:null}
        {visible||visibility.always? <AlertTypesFilter alwaysOn {...props} />:null}
        {visible||visibility.always?  <DatePicker alwaysOn {...props}></DatePicker>:null}
        {visible||visibility.always? <p alwaysOn> Total : {props.total}</p>:null}
           
            
        </Filter>
    );
}

export { AlertsFilter, UsersFilter, RetreatsFilter };
