import React from "react"
import { element } from "prop-types";
import {Button} from "@material-ui/core"
const PositionViewer = ({ record = {}, source }) =>{
   const updatedWithPos = element => {
      if (element.lat && element.lng) {
         element.pos = `https://maps.google.com/?q=${element.lat},${element.lng}&z=8`
         element.hasPos = true;
      }
      else {
         element.pos = "Non spécifié(e)"
      }
      return element;
   }
   if(!record.hasPos && record.lat && record.lng){
      record = updatedWithPos(record)
   }
   if(!record.pos){
      record.pos="Non spécifié(e)"
      record.hasPos=false
   }
   return(
<Button color={"primary"} disabled={!record.hasPos} onClick={e => e.isPropagationStopped=true} target={'_blank'} href={record.hasPos?record[source]:null}>{record.hasPos?"voir map":record[source]}</Button>
)
};
export default PositionViewer;