import React, { useState, useEffect } from 'react';
import { Collapse, Box, Button } from '@material-ui/core';
import { TextField, useDelete,useRefresh, Datagrid, ArrayField, Edit, TextInput, SimpleForm } from "react-admin"
import MyPdfField from "./pdfViewer"
import { Link } from "react-router-dom"

const ExpandedCategories = (props) => {
    const [view, setView] = useState(false)
    const { record = {}, source } = props;
    useEffect(
        () => {
            setView(!view)
            return () => {
                console.log("cleanup called")
                setView(false)
            }
        }, []
    )
    const { content } = record;
    if (!content) return null;              //prevents re-rendering issues when re-mounting the element through "previous page" on the browser, initializing the visual component to null before updating it on mount
    const DisplayContent = ({category}) => {
        const refresh = useRefresh()
        const [del, state] = useDelete('categories', `${category.country_code}_${category.id}`)
        const handleDelete = () => {
            del()
            refresh()
        }
        return (
            <li key={category.id} style={{ display: "flex", flexDirection: "column", alignItems:"center"}}>
                    
                        
                        <div><label>Titre français : </label><TextField record={category} source="title_fr" /></div> 
                        <div><label>Titre anglais : </label><TextField record={category} source="title_en" /></div> 
                        <div><label>Titre espagnol : </label><TextField record={category} source="title_sp" /></div> 

                            <MyPdfField record={category} />
                        <Link to={`categories/${category.id}`}><Button variant="outlined" color="primary" style={{ marginTop: "1vh" }} >Editer</Button></Link>
                        <Button variant="outlined" color="secondary" style={{ marginTop: "1vh" }} onClick={handleDelete}>Supprimer</Button>

                        <hr style={{width:"50vw", margin:"3vh auto"}} />
                    </li>
        )
    }
    const DisplayCategories = () => {
        return(
            <>
            <ul>
                {content.map((category, index)=> (
                   <DisplayContent category={category}/>
                )
)}
        
            </ul>

                <Link style={{textDecoration:"none"}}to={`countries/create?usablecode=${content[0].country_code}`}>  <Button variant="contained" color="secondary"> Ajouter une catégorie</Button></Link>  
        </>
        )

    }
    return (
        <div>
        {DisplayCategories()}
        </div>
    )
}


export default ExpandedCategories;