import axios from "axios"
const apiUrl = process.env.REACT_APP_API_URL || "http://localhost"
export default {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
        
        const admin = username
        try{
        const token = (await axios.post(apiUrl+"/login", {admin, password})).data.token
            
        if(token){
            localStorage.setItem('authTokenFast', token);
        return Promise.resolve();
        }
        else{
        return Promise.reject()
        }
    }
    catch(err) {
        console.log(err)
    }
        
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('authTokenFast');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('authTokenFast');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('authTokenFast')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};