// in src/MyUrlField.js
import * as React from "react";
import { Document, Page,pdfjs } from 'react-pdf'
import { useState } from "react";
import {Button, Box} from "@material-ui/core"
const baseUrl = process.env.REACT_APP_API_URL;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const MyPdfField = ({ record = {}, style, displayButton }) =>{
    const token = localStorage.getItem('authTokenFast');
    const [isDisplayed, setIsDisplayed] = useState(false)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState(null)
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        }
        const onDocumentLoadError = (err) => {
            console.log(err)
            console.log("the pdf loading failed")
            }
    const showOrHidePdf= e =>{ 
        e.preventDefault()
        setIsDisplayed(!isDisplayed)
        if(!file){
            try{

            setFile(
                {
                    url: `${baseUrl}/pdf/${record.country_code}_${record.id}.pdf`,
                    httpHeaders: { 'Accept': 'application/pdf', "Content-Type": "application/pdf", 'Authorization': 'Bearer ' + token }    
                }
            )
        }
        catch(err){
            onDocumentLoadError(err)
        }

        }
        else{
            setFile(null)
        }
        if(displayButton){
            displayButton(!isDisplayed)
        }
    }
    return(
        <Box style={style || { display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "2vh"}}>
            <Button variant="contained" color="primary" onClick={showOrHidePdf}>
            {isDisplayed?"Cacher le PDF":'Voir le PDF'}</Button>
        <div
        style={isDisplayed?null:{display:"none"}
    //
}
        >
        <Document 
                    file={file||null}
                    error="Ce PDF n'est pas disponible."
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading="Chargement..."
                    noData="Pas de PDF spécifié"
                    onLoadError={onDocumentLoadError}
                    onSourceError={onDocumentLoadError}
        >
        <Page pageNumber={pageNumber} />
        </Document>
        </div>
        <div>
{        isDisplayed&&pageNumber>1?<button onClick={() => setPageNumber(pageNumber - 1)}>
                    Précédent
</button>:""}
                {numPages && isDisplayed ? `Page ${pageNumber} sur ${numPages}` : ""}{isDisplayed && numPages&&pageNumber < numPages ?<button onClick={() => setPageNumber(pageNumber + 1 )}>
                Suivant
</button>:""}
            </div>
        {isDisplayed?<Button variant="contained" color="primary" onClick={showOrHidePdf}>Cacher le PDF</Button>:""}

        </Box>  
    )
   }
export default MyPdfField;

// const PdfLink = ({ record = {}, servicesIndex }) => {
    
// return(
// <Button variant="contained" color="primary" href={baseUrl+"countries/pdf/"+record.services[servicesIndex].id}>
//   Voir le PDF
// </Button>);
// }
// export {PdfLink};