import React from 'react';
import {BooleanField, Datagrid, DateField, List, TextField} from 'react-admin';
import {GroupsFilter} from "./filter/GroupsFilter";
import {ListActions, ListPagination} from "../../elements/Actions";

export const GroupList = (props) => (
    <List {...props} title="Liste des groupes" pagination={<ListPagination/>} actions={<ListActions/>} filters={<GroupsFilter />} sort={{field:"createdAt", order:"DESC"}}>
        <Datagrid rowClick="show" >
            <TextField source="name" label="Nom"  />
            <DateField source="createdAt" label="Date de création du groupe" showTime />
            <DateField source="endingDate" label="Date de fin du groupe" />
            <TextField source="price" label="Prix"  />
            <BooleanField source="isTemporary"  label="Temporaire" />
            <TextField source="membersCount" label="Membres"  />
        </Datagrid>

    </List>
);
