import React from 'react';
import {BooleanField, Datagrid, DateField, List, ReferenceField, TextField} from 'react-admin';
import PositionViewer from "./positionViewer"
import MyChipsField from "./categoriesChipIterator"
import ExpandedCategories from "./ExpandedCategories"
import {AlertsFilter, RetreatsFilter, UsersFilter} from "./Filters"
import {ListActions, ListPagination} from "./Actions"

const queryString = require('query-string');
// const AllButtons = ({location}) => {
//     const parsed = queryString.parse(location.search);
//     const drugs = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Drogues",
//         title_en: "Drugs",
//         title_sp: "Drogas",
//     }},
//     style: {
//         backgroundColor:"black",
//         color:"white"
//     }
// }
//     const currency = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Monnaie",
//         title_en: "Currency",
//         title_sp: "Moneda",
//     }},
//     style: {
//         backgroundColor:"green",
//         color:"white"
//     }

// }
//     const travel = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Quand voyager",
//         title_en: "When to travel",
//         title_sp: "Cuando viajar",
//     }},
//     style: {
//         backgroundColor:"#0066FF",
//         color:"white"
//     }

// }
//     const taxi = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Taxi",
//         title_en: "Taxi",
//         title_sp: "Taxi",
//     }},
//     style: {
//         backgroundColor:"#FFCC00",
//         color:"black"
//     }

// }
//     const clothing = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Vestimentaire",
//         title_en: "Clothing",
//         title_sp: "Ropa",
//     }},
//     style: {
//         backgroundColor:"grey",
//         color:"white"
//     }

// }
//     const vaccination = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Vaccination",
//         title_en: "Vaccination",
//         title_sp: "Vacunacion",
//     }},
//     style: {
//         backgroundColor:"white",
//         color:"black"
//     }

// }
//     const visa = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Visa",
//         title_en: "Visa",
//         title_sp: "Visa",
//     }},
//     style: {
//         backgroundColor:"#634133",
//         color:"white"
//     }

// }
//     const insurance = {state:{record: { 
//         country_code:parsed.usablecode,
//         title_fr: "Assurance",
//         title_en: "Insurance",
//         title_sp: "Seguro",
//     }},
//     style: {
//         backgroundColor:"#E0FFFF",
//         color:"black"
//     }

// }
//     const base = [drugs, currency, travel, taxi, clothing, vaccination, visa, insurance]
//     return base.map(model => <CreateCategory goal={model} />)
// }
// const CreateCategory = ({ record, goal }) => {

//    return( 
//    <Button
//         component={Link}
//         to={{
//             pathname: '/countries/create',
//             state: goal.state,
//         }}
//         variant="contained"
//         //color="primary"
//         style={goal.style
//         }
//     >
//         {goal.state.record.title_fr}
//     </Button>
// )};
export const UserList = (props) => (
    <List {...props} title="Liste des utilisateurs" pagination={<ListPagination/>} actions={<ListActions removeCreateButton/>} filters={<UsersFilter/>} sort={{field:"createdAt", order:"DESC"}}> 
        <Datagrid rowClick="show" >
            <BooleanField source="vigil"  label="Vigilant" />
            <DateField source="createdAt" label="Date de création du compte" showTime />         
            <TextField source="nickname" label="Pseudo"  />
            <TextField source="email" label="Email"/>
            <TextField source="dialCode" label="Identifiant téléphone"/>
            <TextField source="phoneNumber" label="Numéro de téléphone"/>
            <TextField source="provider" label="Provider"/>
            <DateField source="birthDate" label="Date de naissance" /> 
{/*             
            <ReferenceField label="Référent" source="id" reference="referents">
                <TextField source="name" />
            </ReferenceField> */}
                        <PositionViewer source="pos" label = "Position"/>
                        
        </Datagrid>

    </List>
);

export const AlertList = (props) => {


    return(<List {...props} filters={<AlertsFilter/>} pagination={<ListPagination/>} actions={<ListActions/>} sort={{field:"createdAt", order:"DESC"}} title="Liste des alertes et gestion d'abus">
        <Datagrid rowClick="edit">
            <TextField source="description" label="Description"  />
            <TextField source="moreInfo" label="Plus d'informations" />
            <DateField source="createdAt" label="Heure de lancement" showTime />         
            <BooleanField source="isUserInjured" label="Blessure" />
            <BooleanField source="isUserVictim" label="Victime" />
            <PositionViewer sortable={false} source="pos" label="Position" />
            <ReferenceField label="Lanceur d'alerte" source="userId" link={"show"} reference="users">
                <TextField source="nickname" />
            </ReferenceField>
            <ReferenceField label="Catégorie d'alerte" source="alertTypeId" link={null} reference="alertTypes" sortable={false}>
                <TextField source="category" />
            </ReferenceField>
            <ReferenceField label="Sous-catégorie d'alerte" source="alertTypeId" link={null} reference="alertTypes">
                <TextField source="name" />
            </ReferenceField>
            <BooleanField sortable={false} source="isArchived" label="Archivé" />
            <BooleanField sortable={false} source="isAnonymous" label="Anonymisé" />
            <TextField source="reportState" label="Etat (gestion d'abus)" />
            <ReferenceField label="Infos Agresseur" source="aggressorId" link={"show"} reference="aggressors">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField label="infos Victime" source="victimId" link={"show"} reference="victims">
                <TextField source="id" />
            </ReferenceField>


        </Datagrid>
    </List>
    
)};
export const ProductList = (props) => (
    <List {...props} pagination={<ListPagination/>} actions={<ListActions/>} title="Liste des produits">
        <Datagrid rowClick="edit">
            <TextField source="name" label="Nom" />
            <TextField source="type" label="type de produit"/>
            <TextField multiline source="description" />
            <TextField source="price" label="Prix" />
        </Datagrid>
    </List>
);
export const RetreatList = (props) => (
    <List {...props} filters={<RetreatsFilter />} pagination={<ListPagination/>} actions={<ListActions removeCreateButton />} sort={{field:"createdAt", order:"DESC"}} title="Liste des lieux de protection">
        <Datagrid rowClick="edit">
            <BooleanField source="temporary" label="Temporaire" />
            <BooleanField source="validated" label="Validé" />
            <TextField source="title" label="Titre" />
            <TextField source="phoneNumber" label="Numéro de téléphone"/>
            <TextField multiline source="comment" label="Commentaire" />
            <TextField source="reportState" label="Etat (gestion d'abus)" />
            <DateField source="createdAt" label="Heure de lancement" showTime />         
            <PositionViewer sortable={false} source="pos" label = "Position"/>
        </Datagrid>
    </List>
);
export const CountryList = (props) => {
    // expand={<ExpandedCategories />}
    return (

    <List {...props} pagination={<ListPagination/>} actions={<ListActions/>} title="Liste des pays et catégories associées">
            <Datagrid expand={<ExpandedCategories />}>
        {/* <AllButtons {...props}></AllButtons> */}

                <TextField source="id" label="Code ISO-2 du pays" />         
                <MyChipsField label="Catégories"></MyChipsField>
            {/* <TextField source="id"/>
            <TextField source="content[0].id" label ="Nom du pays"/>
             */
            }
        </Datagrid>
    </List>

);
}
// export const categoryList = (props) => (
//     <List {...props} title="Liste des Catégories">
//         <Datagrid rowClick={(rowId) => console.log(rowId)}>
//             <TextField source="name" label="Nom du pays" />
//             <TextField source="code" label="Code identifiant pays" />
//             <MyChipsField label="Catégories"/>
//         </Datagrid>
    
//     </List>

// );
