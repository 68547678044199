import {makeStyles} from "@material-ui/core";

export const useStandardStyle = makeStyles(theme => ({
    select: {
        marginBottom: theme.spacing(1),
    },
    tag: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginBottom: theme.spacing(1),

    },
    p: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),

    },
    cardDatePicker : {
        borderTop:"3px solid #0066FF",
        display:"flex",
        position:"absolute",
        top:"20vh", left:"10vw",
        zIndex:30, opacity:1,
        backgroundColor:"white",
        padding:"20px",
        margin:"20px"}
}));
