// in src/App.js
import React from 'react';
import { Admin, Resource, ListGuesser } from 'react-admin';
import {UserList, ProductList, RetreatList, AlertList, CountryList} from "./elements/Lists"
import authProvider from "./authProvider"
import {UserEdit, PdfEdit, AlertEdit, RetreatEdit, ProductEdit, GroupEdit} from './elements/Edit';
import { PdfCreate, ProductCreate} from './elements/Create'
import dataProvider from "./dataProvider"
import { createMuiTheme } from '@material-ui/core/styles';
import Dashboard from "./Dashboard"
import {UserShow, AggressorShow, VictimShow} from './elements/Show';
import Layout from "./elements/Layout"
import PeopleIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import FlightIcon from '@material-ui/icons/Flight';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GroupIcon from '@material-ui/icons/Group';
import {GroupList} from "./groups/list/GroupsList";
import {GroupShow} from "./groups/show/GroupShow";
import {GroupCreate} from "./groups/create/GroupCreate";
// dashboard={Dashboard}  
const theme = createMuiTheme({
    palette: {
        type: 'light', // Switching the dark mode on is a single property value change.
    },
    overrides:{
        MuiAppBar:{
            colorSecondary:{
            backgroundColor:"#0066FF"

            }
        }
    }
});
const App = () => (
    <Admin  dashboard={Dashboard} options={{ label: "Accueil" }} theme={theme} authProvider={authProvider} dataProvider={dataProvider} layout={Layout}>
        <Resource icon={PeopleIcon} name="users" edit={UserEdit} show={UserShow} options={{label:"Utilisateurs"}} list={UserList} />
        <Resource icon={GroupIcon} name="groups" show={GroupShow} options={{label:"Groupes"}} list={GroupList} create={GroupCreate} />
        <Resource icon={PriorityHighIcon} name="alerts" list={AlertList} edit={AlertEdit} options={{label:"Alertes"}} />
        <Resource icon={HomeIcon} name="retreats" list={RetreatList} edit={RetreatEdit} options={{label:"Lieux de protection"}} />
        <Resource icon={FlightIcon} name="categories" edit={PdfEdit} list={CountryList} create={PdfCreate} options={{label:"Voyage"}} />
        <Resource icon={AttachMoneyIcon} name="products" list={ProductList} edit={ProductEdit} create={ProductCreate} title="Produits" options={{label:"Gérer les produits"}} />
        <Resource name="alertTypes" />
        <Resource name="aggressors" show={AggressorShow} />
        <Resource name="victims" show={VictimShow} />
        <Resource name="referents" />
        <Resource name="groupsUsers" />
        <Resource name="transactions" />
        <Resource name="groupsAccessCodes" />

        </Admin>
);
export default App;
