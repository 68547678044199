import React from 'react';
import {
    BooleanField,
    CreateButton,
    Datagrid,
    DateField,
    List,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField, useListContext
} from 'react-admin';
import {ListPagination} from "../../elements/Actions";
import {Box, Button, Typography} from "@material-ui/core";
import {baseUrl} from "../../dataProvider";

export const GroupShow = (props) => {
    return (
        <Show {...props} title="Détails du groupe: ">
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" label="Nom" />
                <DateField source="createdAt" label="Date de création du groupe" showTime />
                <DateField source="endingDate" label="Date de fin du groupe" />
                <TextField source="price" label="Prix"  />
                <BooleanField source="isTemporary"  label="Temporaire" />
                <GroupRelatedUsers {...props} resource="groupsUsers" id={props.id} />
                <GroupRelatedTransactions {...props} resource="transactions" id={props.id} />
                <GroupRelatedAccessCodes {...props} resource="groupsAccessCodes" id={props.id} />
            </SimpleShowLayout>
        </Show>
    )
}

const GroupRelatedUsers = (props) => {
    const {id} = props;
    return(
        <>
            <h2>Membres</h2>
            <List
                empty={<Empty/>}
                {...props}
                resource="groupsUsers"
                actions={null}
                filter={{id}}
                pagination={<ListPagination/>}
                bulkActionButtons={false}
            >
                <Datagrid>
                    <TextField source="userId" label="UserId" />
                    <ReferenceField label="Email" source="userId" reference="users" link={(record, reference) => `/${reference}/${record.userId}/show`}>
                        <TextField source="email" />
                    </ReferenceField>
                    <BooleanField sortable={false} source="isUserActivatedGeoloc" label="Localisation activée" />
                    <TextField source="platform" label="Plateforme" />
                </Datagrid>

            </List>
        </>
    )
}

const GroupRelatedTransactions = (props) => {
    const {id} = props;
    return(
        <>
            <h2>Transactions</h2>
            <List
                empty={<Empty/>}
                {...props}
                resource="transactions"
                actions={null}
                filter={{id}}
                pagination={<ListPagination/>}
                bulkActionButtons={false}
            >
                <Datagrid>
                    <TextField source="userId" label="UserId" />
                    <ReferenceField label="Email" source="userId" reference="users" link={(record, reference) => `/${reference}/${record.userId}/show`}>
                        <TextField source="email" />
                    </ReferenceField>
                    <TextField source="price" label="Prix"  />
                    <DateField source="createdAt" label="Date d'achat" showTime />
                    <TextField source="platform" label="Plateforme" />
                </Datagrid>

            </List>
        </>
    )
}

const GroupRelatedAccessCodes = (props) => {
    const {id} = props;

    const addAccessCode = () => {
        console.log(localStorage.getItem("authTokenFast"));
        console.log(`${baseUrl}/groups/${id}/accessCode`)

        const body = {
            platform: "admin",
        }
        const url = `${baseUrl}/groups/${id}/accessCode`;
        const authHeader = `Bearer ${localStorage.getItem("authTokenFast")}`;
        return fetch(url, {
            method: 'POST', headers: {
                Accept: 'application/json',
                Authorization: authHeader,
                'Content-Type': 'application/json'
            }, body: JSON.stringify(body)
        })
            .then(body => {
                window.location.reload();
            })
            .catch((e) => {
            });
    }

    return(
        <>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <h2>Codes d'accès</h2>
                <Button onClick={addAccessCode} color="primary">Ajouter un code</Button>
            </div>
            <List
                empty={<Empty/>}
                {...props}
                resource="groupsAccessCodes"
                actions={null}
                filter={{id}}
                pagination={<ListPagination perPage={100} />}
                bulkActionButtons={false}
            >
                <Datagrid>
                    <TextField source="accessCode" label="Code d'accès" />
                    <ReferenceField label="Abonnement utilisateur invité" source="invitedUserId" reference="users" link={(record, reference) => `/${reference}/${record.userId}/show`}>
                        <TextField source="email" />
                    </ReferenceField>
                </Datagrid>

            </List>
        </>
    )
}

const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            No Data
        </Typography>
    </Box>
);
