import React from 'react';
import {
    BooleanInput,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    DateInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    SearchInput,
    required
} from 'react-admin';

const queryString = require('query-string');

export const GroupCreate = (props) => (
    <Create title="Créer un groupe" {...props}>
        <SimpleForm>
            <p>Laisser la durée et la date de fin vide pour créer un groupe non-temporaire</p>
            <TextInput name="name" label="Nom du groupe" validate={required()}/>
            <NumberInput source="price" label="Prix (en centimes)" validate={[required()]} />
            <NumberInput source="nbMonths" label="Durée (en mois)" />
            <DateInput source="endingDate" label="Date de fin" />
            <NumberInput source="nbAccess" label="Nombre d'accès" />
            <NumberInput source="userId" label="UserId" validate={[required()]} />
        </SimpleForm>
    </Create>
);
