import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from "react-admin";
export const baseUrl = process.env.REACT_APP_API_URL;
const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    const token = localStorage.getItem('authTokenFast');
    options.headers.set('Authorization', 'Bearer ' + token);

    return fetchUtils.fetchJson(url, options);
}
const dataProvider = jsonServerProvider(baseUrl, fetchJson);
const extendedDataProvider = {
    ...dataProvider,
    create: (resource, params) => {
        switch(resource) {
            case "groups":
                return new Promise(async (resolve, reject) => {
                    params.data = {
                        ...params.data,
                        platform: "admin",
                        nbAccess: params.data.nbAccess ? params.data.nbAccess : 0,
                    }
                    try {
                        const newGroup = await dataProvider.create("customGroups", params)
                        resolve(newGroup)
                    }
                    catch (err) {
                        reject(err)
                    }
                })
            // case "countries" : 
            //     const formData = new FormData();
            //     Object.keys(params.data).forEach(key => {
            //         if (key === "file") {
            //             formData.append(key, params.data[key].rawFile)
            //         }
            //         else {
            //             formData.append(key, params.data[key])
            //         }
            //     })
            //     return fetchJson(`${baseUrl}/categories/upload`, {
            //         method: 'POST',
            //         body: formData,
            //     }).then(function (_a) {
            //         var json = _a.json;
            //         return ({ data: json });
            //     });
            default:
                return dataProvider.create(resource, params)
        }
    } ,
    update: (resource, params) => {
        switch(resource) {
        // case "countries" :
        //     const formData = new FormData();
        //     Object.keys(params.data).forEach(key => {
        //         if (key === "file") {
        //             formData.append(key, params.data[key].rawFile)
        //         }
        //         else {
        //             formData.append(key, params.data[key])

        //         }
        //     })
        //     return fetchJson(`${baseUrl}/categories/upload/${params.id}`, {
        //         method: 'PUT',
        //         body: formData,
        //     }).then(function (_a) {
        //         var json = _a.json;
        //         return ({ data: json });
        //     });
            // return dataProvider.update("categories/upload", {id:params.id, data:formData })
        
        default : {
            return dataProvider.update(resource, params)
        }
        }
    },
    simpleGet: (resource) => {
        return fetchJson(`${baseUrl}/${resource}`).then(({ json }) => ({
            data: json,
        }))
    }
    ,
    getOne: (resource, params) => {
        switch (resource) {
            case "alerts":
                return new Promise(async (resolve, reject) => {

                    try {
                        const alert = await dataProvider.getOne(resource, params)
                        alert.data = isAnonymous(alert.data)
                        resolve(alert)
                    }
                    catch (err) {
                        reject(err)
                    }
                })
                // case "countries":
                // return dataProvider.getOne("categories", params)
            case "users":
                return new Promise(async (resolve, reject) => {

                    try {
                        const user = await dataProvider.getOne(resource, params)
                        user.data = updatedWithPos(user.data)
                        resolve(user)
                    }
                    catch (err) {
                        reject(err)
                    }
                }
                )
            case "groups":
                return new Promise(async (resolve, reject) => {

                        try {
                            const group = await dataProvider.getOne(resource, params)
                            group.data = updateGroup(group.data)
                            resolve(group)
                        }
                        catch (err) {
                            console.log(err);
                            reject(err)
                        }
                    }
                )

            default:
                return dataProvider.getOne(resource, params)
        }
    },
    getList: (resource, params) => {
        
        switch (resource) {
            case 'users':
                return new Promise(async (resolve, reject) => {
                    try {
                        const userList = await dataProvider.getList(resource, params);
                        userList.data = updatedListWithPos(userList)

                        resolve(userList)
                    }
                    catch (err) {
                        console.log(err)
                        reject(err)
                    }
                })
            case 'groups':
                return new Promise(async (resolve, reject) => {
                    try {
                        const groupList = await dataProvider.getList(resource, params);
                        groupList.data = updateGroups(groupList.data)

                        resolve(groupList)
                    }
                    catch (err) {
                        console.log(err)
                        reject(err)
                    }
                })
            case 'transactions':
                return new Promise(async (resolve, reject) => {
                    try {
                        const transactionList = await dataProvider.getList(resource, params);
                        transactionList.data = updateTransactions(transactionList.data)

                        resolve(transactionList)
                    }
                    catch (err) {
                        console.log(err)
                        reject(err)
                    }
                })
            case 'alerts':
                return new Promise(async (resolve, reject) => {
                    try {
                        const alertList = await dataProvider.getList(resource, params);
                        alertList.data = updatedListWithPos(alertList)
                        alertList.data = areAnonymous(alertList)

                        resolve(alertList)
                    }
                    catch (err) {
                        console.log(err)
                        reject(err)
                    }
                })
            case 'retreats':
                return new Promise(async (resolve, reject) => {
                    try {
                        const retreatsList = await dataProvider.getList(resource, params);
                        retreatsList.data = updatedListWithPos(retreatsList)
                        resolve(retreatsList)
                    }
                    catch (err) {
                        console.log(err)
                        reject(err)
                    }
                })
            //  case 'countries':
            // return dataProvider.getList("categories", params)
            default: //fallback to default implementation
                return dataProvider.getList(resource, params);
        }
    }
}
const isAnonymous = alert => {
    alert.isAnonymous = !parseInt(alert.userId)
    return alert;
}
const areAnonymous = users => users.data.map(isAnonymous)
const updatedListWithPos = (list) => list.data.map(updatedWithPos)
const updatedWithPos = element => {
    if (element.lat && element.lng) {
        element.pos = `https://maps.google.com/?q=${element.lat},${element.lng}&z=8`
        element.hasPos = true;
    }
    else {
        element.pos = "Non spécifié(e)"
    }
    return element;
}

const updateGroups = (groups) => {
    return groups.map(updateGroup);
}

const updateGroup = (group) => {
    return ({
        ...group,
        price: `${group.price / 100}€`,
        isTemporary: !!group.endingDate,
    })
}

const updateTransactions = (transactions) => {
    return transactions.map(updateTransaction);
}

const updateTransaction = (transaction) => {
    return ({
        ...transaction,
        price: `${transaction.price / 100}€`,
    })
}

export default extendedDataProvider;
