import React, { useState, useEffect } from 'react';
import {
    List,
    Show,
    SimpleShowLayout,
    useQuery,
    Loading,
    TextField,
    BooleanField,
    DateField,
    useListContext,
    ReferenceManyField,
    ReferenceField,
    Datagrid,
    ArrayField,
    NumberInput,
    FileField,
    UrlField,
    TextInput
} from 'react-admin';
import { Box, Chip } from '@material-ui/core';
import {ListPagination, ShowActions} from "./Actions"
import {AlertsFilter} from "./Filters"
import PositionViewer from "./positionViewer"

const QuickFilter = (props) => {
    return(
    <Chip style={{marginBottom:"8px"}} label={`ID utilisateur : ${props.id}`} />)
}
const UserRelatedAlerts = (props) => {
    const {id} = props;
    const [visible, setVisible] = useState(false)
    console.log(props);
    return(
    <List component="Box" {...props} resource="alerts" actions={null} filter={{id}} filters={<AlertsFilter hasAdditionalFilters={true} visibility={{visible, setVisible}} userId={id} additionalFilters={<QuickFilter {...props} alwaysOn source="id"/>} filterDefaultValues={{userId:id}} />} pagination={visible?<ListPagination/>:null}> 
        <Datagrid style={{display:visible?"table":"none"}}>
            <TextField source="description" label="Description" />
            <TextField source="moreInfo" label="Plus d'informations" />
            <BooleanField source="isUserInjured" label="Blessure" />
            <BooleanField source="isUserVictim" label="Victime" />
            <DateField source="createdAt" label="Heure de lancement" showTime />         
            <ReferenceField label="Catégorie d'alerte" source="alertTypeId" link={null} reference="alertTypes" sortable={false}>
                <TextField source="category" />
            </ReferenceField>
            <ReferenceField label="Sous-catégorie d'alerte" source="alertTypeId" link={null} reference="alertTypes">
                <TextField source="name" />
            </ReferenceField>
            <PositionViewer sortable={false} source="pos" label="Position" />
            <BooleanField sortable={false} source="isArchived" label="Archivé" />
            <TextField source="reportState" label="Etat (gestion d'abus)" />

        </Datagrid>

</List>

    )
}
export const UserShow = (props) => {
    return (
    <Show {...props} actions={<ShowActions removeCreateButton />} title="Détails de l'utilisateur et de ses alertes : ">
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="nickname" label="Pseudo" />
            <TextField source="email" label="Email" />
            <TextField source="dialCode" label="Identifiant d'appel (pays)"/>
            <TextField source="phoneNumber" label="Numéro de téléphone"/>
            <BooleanField source="vigil" label="Vigilant" />
            <p>Position : </p>
            <PositionViewer source="pos" label="Position" />
            <UserRelatedAlerts {...props} resource="alerts" id={props.id} />


        </SimpleShowLayout>

    </Show>
    )
}

export const AlertShow = (props) => (
    <Show {...props} title="Détails d'alerte">
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="description" label="Description" />
            <TextField source="moreInfo" label="Plus d'informations" />
            <BooleanField source="isUserInjured" label="Blessure" />
            <BooleanField source="isUserVictim" label="Victime" />
            <PositionViewer sortable={false} source="pos" label="Position" />
            <ReferenceField label="Lanceur d'alerte" source="userId" reference="users">
                <TextField source="nickname" />
            </ReferenceField>
            <BooleanField source="isArchived" label="Archivé" />
            <TextField source="reportState" label="Etat (gestion d'abus)" />
            <ReferenceField label="Infos Agresseur" source="aggressorId" link={"show"} reference="aggressors">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField label="infos Victime" source="victimId" link={"show"} reference="victims">
                <TextField source="id" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);

export const VictimShow = (props) => {
    return (
    <Show {...props} aside={<SideImgVictim/>} title="Détails de la victime :  ">
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="firstName" label="Prénom : " />
            <TextField source="lastName" label="Nom : " />
            <TextField source="phoneNumber" label="Numéro de téléphone : " />
            <TextField source="registration" label="Immatriculation : " />
            <p>Description :</p>
                <TextField source="size" label="Taille :" />
                <TextField source="hair" label="Cheveux :" />
                <TextField source="hairColor" label="Couleur des cheveux :" />
                <TextField source="skin" label="Peau : " />
            <TextField source="clothingDescription" label="Description physique/habits : " />
            <TextField source="clothingMoreInfos" label="Informations additionnelles : " />
            <BooleanField source="isMan" label="Est un homme :" />
            <BooleanField source="isWoman" label="Est une femme :" />
            <BooleanField source="hasBag" label="A un sac :" />
                <BooleanField source="hasHat" label="A un chapeau :" />
                <BooleanField source="hasJewelry" label="A des bijoux :" />
                <BooleanField source="hasMustache" label="A une moustache :" />
                <BooleanField source="hasScar" label="A une cicatrice :" />
                <BooleanField source="hasTattoo" label="A un tatouage :" />


        </SimpleShowLayout>

    </Show>)}
    export const AggressorShow = (props) => {
        return (
        <Show {...props} aside={<SideImgAggressor/>} title="Détails de l'agresseur : ">
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="registration" label="Immatriculation : " />
                <BooleanField source="isMan" label="Est un homme :" />
                <BooleanField source="isWoman" label="Est une femme :" />
                <p>Description :</p>
                <TextField source="size" label="Taille :" />
                <TextField source="hair" label="Cheveux :" />
                <TextField source="hairColor" label="Couleur des cheveux :" />
                <TextField source="skin" label="Peau : " />
                <TextField source="clothingDescription" label="Description physique/habits : " />
                <TextField source="clothingMoreInfos" label="Informations additionnelles : " />
                <BooleanField source="hasBag" label="A un sac :" />
                <BooleanField source="hasHat" label="A un chapeau :" />
                <BooleanField source="hasJewelry" label="A des bijoux :" />
                <BooleanField source="hasMustache" label="A une moustache :" />
                <BooleanField source="hasScar" label="A une cicatrice :" />
                <BooleanField source="hasTattoo" label="A un tatouage :" />
                
            </SimpleShowLayout>
    
        </Show>)}

const SideImgVictim = (props) => {
    const [source, setSource] = useState("")
    const [source2, setSource2] = useState("")

    const [id, setId] = useState(null)
    useEffect(() => {
        console.log("used effect")
        console.log(id)
        const func = (path, setter) => {
        if(id){
        const src = path+id+".png";
        const token = localStorage.getItem('authTokenFast');
        const options = {
            headers: {
              'Authorization': 'Bearer '+token
            }
          };
fetch(src, options)
.then(res =>{
    if(res.status!==404){
        return res.blob()
    }
    else{
        return null
    }
})
.then(blob => {
    if(blob){
  setter(URL.createObjectURL(blob));

    }
});
}
}
func(process.env.REACT_APP_API_URL+'/alerts/victims/fileVictim_', setSource)
func(process.env.REACT_APP_API_URL+'/image/victimCar/fileVictimCar_', setSource2)

    },[id])

    const FetchAlert = () => {
        const alertId = window.location.hash.split("/")[2]

        const { data, loading, error } = useQuery({ 
            type: 'simpleGet',
            resource: 'correspondingalert/'+alertId,
        });

        if (error) {
            return <p>ERROR: {error}</p>
        }
        if (loading) {
            return <Loading/>
        }
        if(data && data[0] && data[0][0] && !id){
        setId(data[0][0].id)
        }
        //console.log(newAlert)
        const FetchImg = () => {

            let imgElement = <img style={{height:"auto", width:"80%"}} src={source}/>
          

        if (source){
    return imgElement}
        }
        const FetchImg2 = () => {
            let imgElement = <img style={{height:"auto", width:"60%"}} src={source2}/>
            if (source2){
            return imgElement
            }

        }
    return (
        <div style={{width:"60%", display:"flex", alignItems:"center", flexDirection:"column", margin:"1vh", padding:"1vh"}}>
      <h4>{source?`Photographie de la victime : `:`L'utilisateur n'a pas renseigné de photographie de la victime.`}</h4>
   { FetchImg()}
   <h4>{source2?`Photographie de la voiture de la victime :`:`L'utilisateur n'a pas renseigné de photographie de véhicule.` }</h4>
    {FetchImg2()}
    </div>

    ) 
    }
    return(
        <>
   { FetchAlert()}
        </>
    )
}

const SideImgAggressor = (props) => {
    const [source, setSource] = useState("")
    const [source2, setSource2] = useState("")

    const [id, setId] = useState(null)
    useEffect(() => {
        console.log("used effect")
        console.log(id)
        const func = (path, setter) => {
        if(id){
        const src = path+id+".png";
        const token = localStorage.getItem('authTokenFast');
        const options = {
            headers: {
              'Authorization': 'Bearer '+token
            }
          };
fetch(src, options)
.then(res =>{
    if(res.status!==404||res.data?.status!==404){
    return res.blob()
    }
    else{
        return null
    }
})
.then(blob => {
    if(blob){
  setter(URL.createObjectURL(blob));

    }
});
}
}
func(process.env.REACT_APP_API_URL+'/image/aggressor/fileAggressor_', setSource)
func(process.env.REACT_APP_API_URL+'/image/aggressor/fileAggressorCar_', setSource2)

    },[id])

    const FetchAlert = () => {
        const alertId = window.location.hash.split("/")[2]

        const { data, loading, error } = useQuery({ 
            type: 'simpleGet',
            resource: 'correspondingalert/'+alertId,
        });

        if (error) {
            return <p>ERROR: {error}</p>
        }
        if (loading) {
            return <Loading/>
        }
        if(data && data[0] && data[0][0] && !id){
            console.log(data)
        setId(data[0][0].id)
        }
        //console.log(newAlert)
        const FetchImg = () => {

            let imgElement = <img style={{height:"auto", width:"80%"}} src={source}/>
          

        if (source){
    return imgElement}
        }
        const FetchImg2 = () => {
            let imgElement = <img style={{height:"auto", width:"80%"}} src={source2}/>
            if (source2){
            return imgElement
            }

        }
    return (
        <div style={{width:"60%", display:"flex", alignItems:"center", flexDirection:"column", margin:"1vh", padding:"1vh"}}>
      <h4>{source?`Photographie de l'agresseur : `:`L'utilisateur n'a pas renseigné de photographie de l'agresseur.`}</h4>
   { FetchImg()}
   <h4>{source2?`Photographie de la voiture de l'agresseur : `:`L'utilisateur n'a pas renseigné de photographie de véhicule.`}</h4>

    {FetchImg2()}
    </div>

    ) 
    }
    return(
        <>
   { FetchAlert()}
        </>
    )
}
