import { Menu, Layout } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import React from "react";
import AppBar from "./AppBar"
const styles ={
    menu: {
        backgroundColor: 'blue',
    },
};

const MyMenu = ({ classes, ...props }) => (
    <Menu  {...props} />
);


const MyLayout = (props) => <Layout {...props} appBar={AppBar} menu={ MyMenu} />;

export default MyLayout;