import * as React from "react";
import {Loading, useQuery} from "react-admin";
import {Link} from "react-router-dom"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import logo from "./media/Logo.png"

const baseUrl = process.env.REACT_APP_API_URL;

const Dashboard = (props) => {
        let alerts, users, retreats, activeUsers;

    const { data, loading, error } = useQuery({
        type: 'simpleGet',
        resource: 'allcounts',
    });

    if (loading) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    users = data[0];
    retreats = data[1];
    alerts = data[2];
    activeUsers = data[3].activeTotalUsers;

    const styles = {
        dash: { display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' },
        button: { margin:"3vw" },
    };

    return (
        <Card style={styles.dash}>
            <CardHeader title="Bienvenue sur la page d'administration de FAST" />
            <img src={logo} style={{height:"auto", width:"30%"}} title="Logo Fast"/>
            <CardHeader
                title={
                    <div> il y a un total de {users.totalUsers} utilisateurs, dont {activeUsers} ayant partagés leur position dans la semaine, {retreats.totalPlaces} lieux de protection dont <Link href={"_blank"} to={`/retreats?filter=%7B"type"%3A"reported"%7D&order=DESC&page=1&perPage=10&sort=createdAt`}><b> {retreats.reportedRetreats} ont été signalé(s)</b></Link> et un total de {alerts.totalAlert} alertes, dont <Link href={"_blank"} to={`/alerts?filter=%7B"type"%3A"reported"%7D&order=DESC&page=1&perPage=10&sort=createdAt`}><b> {alerts.reportedAlerts} signalée(s)</b></Link>  et {alerts.activeAlertsCount} actuellement active(s).</div>
                }
            />
            <CardContent><p>Ici vous avez accès aux données de l'application Fast.</p>
                <p>Vous pouvez gérer les utilisateurs ainsi que les alertes, les lieux de protections et les PDFs destinés à la communauté.</p>
            </CardContent>
        </Card>
    );

}
export default Dashboard;
