import React, { useState } from 'react';
import { Create, SimpleForm, FileInput, TextInput, FileField, BooleanInput,required, minLength, maxLength, Link } from 'react-admin';
import MyPdfField from "./pdfViewer"
import { Button,Select, MenuItem, Typography } from "@material-ui/core"
import { useForm } from 'react-final-form'
const queryString = require('query-string');

const drugs = {record: { 
    title_fr: "Drogues",
    title_en: "Drugs",
    title_sp: "Drogas",
    },
name :"drugs"
}
const currency = {record: { 
    title_fr: "Monnaie",
    title_en: "Currency",
    title_sp: "Moneda",
},
name :"currency"
}
const travel = {record: { 
    title_fr: "Quand voyager",
    title_en: "When to travel",
    title_sp: "Cuando viajar",
},
name :"travel"
}
const taxi = {record: { 
    title_fr: "Taxi",
    title_en: "Taxi",
    title_sp: "Taxi",
},
name :"taxi"
}
const clothing = {record: { 
    title_fr: "Vestimentaire",
    title_en: "Clothing",
    title_sp: "Ropa",
},
name :"clothing"
}
const vaccination = {record: { 
    title_fr: "Vaccination",
    title_en: "Vaccination",
    title_sp: "Vacunacion",
},
name :"vaccination"
}
const visa = {record: { 
    title_fr: "Visa",
    title_en: "Visa",
    title_sp: "Visa",
},
name :"visa"
}
const insurance = {record: { 
    title_fr: "Assurance",
    title_en: "Insurance",
    title_sp: "Seguro",
},
name :"insurance"
}
let reference = {drugs, currency, travel, taxi, clothing, vaccination, visa, insurance}
const AllButtons = ({location}) => {
    //const parsed = queryString.parse(location?.search);

    let base = [drugs, currency, travel, taxi, clothing, vaccination, visa, insurance]
    return base.map((model, i) => (    
    <MenuItem
        value={model.name}
         //    component={Link}
         >
         {model.record.title_fr}
         </MenuItem>)//<CreateCategory goal={model} i={i} />)
    )
}
function SelectCategory(props){
    const {change} = useForm()
    const [categorySelected, setCategorySelected] = useState(props.location?.state?.name||0)

    function onChange(e){
        if(e.target.value){
            setCategorySelected(e.target.value)
            change('title_fr', reference[e.target.value].record.title_fr)
            change('title_en', reference[e.target.value].record.title_en)
            change('title_sp', reference[e.target.value].record.title_sp)
    }
        else {
            setCategorySelected(0)
            change('title_fr', '')
            change('title_en', '')
            change('title_sp', '')

    }
     }
    return(
        <Select
        onChange={onChange}
          style={{marginBottom:"20px", 
          }}
            value={categorySelected}
          >  
            <MenuItem
          value={0}
           >
              Aucun modèle sélectionné
           </MenuItem>
              {AllButtons(props)} 
          </Select>
    )
}
export const PdfCreate = (props) => {
    const parsed = queryString.parse(props.location.search);
    const [dropZone, setDropZone] = useState(false);
    // 
    const style = {
        pdfButton: { marginTop: "1vh" },
    }
    const validateIso2 = [required(), minLength(2), maxLength(2)]
    return (

        <Create title="Créer une catégorie" {...props} >
            <SimpleForm >
                <label style={{fontSize:12}}>Modèle de catégorie :</label>
               <SelectCategory {...props}/>         
                <TextInput  label="Code ISO2 Pays (ex: FR)" source="country_code" validate={validateIso2} defaultValue={parsed.usablecode} />
                <TextInput  name="title_fr" label="Titre Français" source="title_fr" validate={required()}/>
                <TextInput  label="Titre Anglais" source="title_en" validate={required()}/>
                <TextInput  label="Titre Espagnol" source="title_sp" validate={required()} />
                {/* <div style={{ display: "block" }}>
                    <Button variant="outlined" color="primary" style={style.pdfButton} onClick={() => setDropZone(!dropZone)}>Ajouter PDF</Button>
                </div>
                <div style={dropZone ? { width: "100%" } : { display: "none" }} >
                    <FileInput  label="PDF" source="file" accept="application/pdf" validate={required()} placeholder={<p>Amenez un fichier jusqu'à cette zone ou cliquez dessus pour sélectionner un nouveau PDF</p>}>
                        <FileField title="name" target="_blank" ></FileField>
                    </FileInput>
                </div> */}
            </SimpleForm>
        </Create>
    );
};
export const ProductCreate = (props) => (
    <Create title="Ajouter un produit" {...props}>
        <SimpleForm>
            <TextInput source="name" label="Nom" />
            <TextInput source="type" label="Type de produit" />
            <TextInput source="description" label="Description" />
            <TextInput source="price" label="Prix" />
        </SimpleForm>
    </Create>
);
export const RetreatCreate = (props) => (
    <Create title="Créer un point de repli" {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="title" label="Titre" />
            <TextInput multiline source="comment" label="Commentaire" />
            <TextInput source="lat" label="Latitude" />
            <TextInput source="lng" label="Longitude" />
            <BooleanInput source="temporary" label="Temporaire" />

        </SimpleForm>
    </Create>
);